import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/students/add',
      name: 'students/add',
      component: () => import('@/views/students/add.vue')
    },
    {
      path: '/students/list',
      name: 'students/list',
      component: () => import('@/views/students/list.vue')
    },
    {
      path: '/students/cards',
      name: 'students/cards',
      component: () => import('@/views/students/cards.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/pages/contact.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/autologin/:id',
      name: 'autologin',
      component: () => import('@/views/pages/autologin.vue')
    },
    {
      path: '/autologin2',
      name: 'autologin2',
      component: () => import('@/views/pages/autologin.vue')
    },
    {
      path: '/balance',
      name: 'balance',
      component: () => import('@/views/students/balance.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/pages/settings.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/views/pages/activate.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/pages/register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/barcode',
      name: 'barcode',
      component: () => import('@/views/students/barcode.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/overview/:id',
      name: 'overview',
      component: () => import('@/views/pages/overview.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/seller',
      name: 'seller',
      component: () => import('@/views/students/seller.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
